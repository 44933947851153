export const themes = {
  light: {
    dark: false,
    colors: {
      primary: "#312783", // Elivie/Blue
      "primary-superlighten": "#FBFBFF", // Elivie/Blue 5%
      "primary-lighten-2": "#DAEFF7", // Elivie/Blue 20%
      "primary-lighten-5": "#AAA5D2", // Elivie/Blue 50%
      "primary-lighten-1": "#F2F0FF", /// Elivie/Blue 10%
      "primary-lighten-07": "#F2F9FE", /// Elivie/Blue 7%
      secondary: "#00ABD7", // Elivie/Blueclair
      tertiary: "#95C11F", // Elivie/vert
      background: "#F2F6FA", // BG/clear
      "on-background": "#312783", // Elivie/Blue/Primary
      surface: "#FFF",
      "on-surface": "#312783", // Elivie/Blue/Primary
      "surface-input": "#F2F6FA",
      "dark-02": "#4A6273", // dark blue 50%
      bodytext: "#312783", // dark blue
      darktext: "#080232", // dark blue
      lighttext: "#737087", // dark blue 50%
      blue: "#19559B",
      "blue-lighten-5": "CDE0FB",
      error: "#D54242",
      info: "#D54242",
      success: "#199B55", // Alert/vert_dark
      "success-clear": "#42ff00", // Alert/vert_clear
      warning: "#C25D00",
      "disabled-border": "#CFD8E2",
      "disabled-bg": "#B4C2D0",
      overlay: "#02040A",
      "green-alert": "#54CC8B",
      "drop-shadow": "#CCBCE5",
      "switch-default": "#CFD8E2",
      gold: "#FFB547",
      "dark-05": "#E8EBED",
    },
    variables: {
      "table-header-color": "#737087",
      "high-emphasis-opacity": 0.9,
      "medium-emphasis-opacity": 0.6,
      "low-emphasis-opacity": 0.4,
    },
  },
};
export default themes;
