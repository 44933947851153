<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8221 7.14837C16.7339 6.06936 15.0158 6.06936 13.9276 7.14837L8.14451 12.8825C8.00282 13.023 7.90751 13.206 7.87209 13.4056C7.17942 17.3088 7.12829 21.3048 7.72085 25.2256L7.8615 26.1562H11.5821V18.1332C11.5821 17.5982 12.0018 17.1645 12.5196 17.1645H19.2301C19.7479 17.1645 20.1676 17.5982 20.1676 18.1332V26.1562H23.8882L24.0288 25.2256C24.6214 21.3048 24.5702 17.3088 23.8776 13.4056C23.8422 13.206 23.7469 13.023 23.6052 12.8825L17.8221 7.14837ZM12.6294 5.75037C14.4431 3.95203 17.3066 3.95203 19.1203 5.75037L24.9033 11.4845C25.3291 11.9066 25.6154 12.4565 25.7218 13.0561C26.4525 17.1735 26.5064 21.3886 25.8814 25.5245L25.6554 27.0196C25.562 27.6379 25.0466 28.0937 24.441 28.0937H19.2301C18.7123 28.0937 18.2926 27.66 18.2926 27.125V19.102H13.4571V27.125C13.4571 27.66 13.0373 28.0937 12.5196 28.0937H7.30866C6.70306 28.0937 6.1877 27.6379 6.09426 27.0196L5.8683 25.5245C5.24324 21.3886 5.29718 17.1735 6.02784 13.0561C6.13426 12.4565 6.42061 11.9066 6.84632 11.4845L12.6294 5.75037Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
