/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "../assets/scss/root.scss";
import "../assets/scss/styles.scss";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

// Vuetify
import { createVuetify } from "vuetify";

// Components
import { VBtn, VAlert, VCard } from "vuetify/components";
import defaults from "./defaults";
import { themes } from "./theme";

// Blueprints
// https://vuetifyjs.com/features/blueprints/
import { md3 } from "vuetify/blueprints";
import { aliases, mdi } from "vuetify/iconsets/mdi";

// Export a function that takes the store as an argument and returns the Vuetify instance
export default function createMyVuetify(store) {
  return createVuetify({
    aliases: {
      VBtnSecondary: VBtn,
      VBtnIcon: VBtn,
      VBtnSmall: VBtn,
      VBtnNav: VBtn,
      VAlertError: VAlert,
      VAlertSuccess: VAlert,
      VAlertInfo: VAlert,
      VCardHelp: VCard,
    },
    defaults,
    components: {},
    blueprint: md3,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    // https://vuetifyjs.com/features/theme/
    theme: {
      defaultTheme: "light",
      themes,
    },
  });
}
