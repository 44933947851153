<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0004 19.5626C11.4826 19.5626 11.0629 19.9823 11.0629 20.5001C11.0629 21.0179 11.4826 21.4376 12.0004 21.4376H14.1879V23.6251C14.1879 24.1429 14.6076 24.5626 15.1254 24.5626C15.6431 24.5626 16.0629 24.1429 16.0629 23.6251V21.4376H18.2504C18.7681 21.4376 19.1879 21.0179 19.1879 20.5001C19.1879 19.9823 18.7681 19.5626 18.2504 19.5626H16.0629V17.3751C16.0629 16.8573 15.6431 16.4376 15.1254 16.4376C14.6076 16.4376 14.1879 16.8573 14.1879 17.3751V19.5626H12.0004Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.18787 7.32657V8.87381C8.72626 8.90862 8.26488 8.9475 7.80378 8.99044C5.71742 9.18476 4.12207 10.9356 4.12207 13.031V22.9692C4.12207 25.0646 5.71742 26.8154 7.80378 27.0097C12.6743 27.4634 17.5764 27.4634 22.4469 27.0097C24.5333 26.8154 26.1286 25.0646 26.1286 22.9692V13.031C26.1286 10.9356 24.5333 9.18476 22.4469 8.99044C21.9859 8.9475 21.5245 8.90862 21.0629 8.87381V7.32657C21.0629 6.24377 20.2707 5.3239 19.1999 5.16328L17.675 4.93454C15.9847 4.681 14.2661 4.681 12.5758 4.93454L11.0509 5.16328C9.98005 5.3239 9.18787 6.24377 9.18787 7.32657ZM17.3968 6.7888C15.891 6.56291 14.3598 6.56291 12.8539 6.7888L11.329 7.01753C11.176 7.04048 11.0629 7.17189 11.0629 7.32657V8.75481C13.7694 8.61536 16.4813 8.61536 19.1879 8.75481V7.32657C19.1879 7.17189 19.0747 7.04048 18.9217 7.01753L17.3968 6.7888ZM22.2731 10.8574C17.5182 10.4145 12.7325 10.4145 7.97766 10.8574C6.85529 10.9619 5.99707 11.9038 5.99707 13.031V13.3126H24.2536V13.031C24.2536 11.9038 23.3954 10.9619 22.2731 10.8574ZM24.2536 15.1876H5.99707V22.9692C5.99707 24.0964 6.85529 25.0383 7.97766 25.1428C12.7325 25.5857 17.5182 25.5857 22.2731 25.1428C23.3954 25.0383 24.2536 24.0964 24.2536 22.9692V15.1876Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
