<template>
  <base.app.layout>
    <content.stretch.layout :background="getLayout.backgroundColor">
      <template #header>
        <HeaderLayout
          v-bind="getHeaderBar.vuetifyParams"
          :class="{
            'header--fixed': getHeaderBar.vuetifyParams.fixedHeader,
          }"
        >
          <template v-if="getHeaderBar && getHeaderBar != null">
            <component
              :is="getHeaderBar.module"
              :params="getHeaderBar.params"
            />
          </template>
        </HeaderLayout>
      </template>
      <template #content>
        <v-container class="h-100" :class="containerClasses">
          <ContentType :content="content" />
        </v-container>
        <!-- Init Dialog -->
        <v-dialog
          v-model="modalOpen"
          v-bind="$store.getters.getModalParam('vuetifyParams')"
        >
          <template
            v-if="
              $store.getters.getModalParam('headerBar') &&
              $store.getters.getModalParam('headerBar') != null
            "
          >
            <HeaderLayout
              v-bind="$store.getters.getModalParam('headerBar').vuetifyParams"
            >
              <component
                :is="$store.getters.getModalParam('headerBar').module"
                :params="$store.getters.getModalParam('headerBar').params"
              />
            </HeaderLayout>
          </template>

          <v-sheet
            :class="{
              'pa-4':
                $store.getters.getModalParam('subVuetifyParams').noPadding !==
                true,
            }"
            v-bind="$store.getters.getModalParam('subVuetifyParams')"
          >
            <v-icon
              v-if="$store.getters.getModalParam('closeBtn')"
              @click="$store.dispatch('closeModal')"
              class="btn-close ml-n1 mt-n2"
              ><CloseIcon size="0.7" class="text-primary"
            /></v-icon>
            <h1 v-if="$store.getters.getModalParam('title')">
              {{ $t($store.getters.getModalParam("title")) }}
            </h1>
            <ContentType :content="$store.getters.getModalParam('content')" />
          </v-sheet>
        </v-dialog>

        <v-dialog
          v-model="modal2Open"
          v-bind="$store.getters.getModalParam('vuetifyParams2')"
        >
          <template
            v-if="
              $store.getters.getModalParam('headerBar2') &&
              $store.getters.getModalParam('headerBar2') != null
            "
          >
            <HeaderLayout
              v-bind="$store.getters.getModalParam('headerBar2').vuetifyParams"
            >
              <component
                :is="$store.getters.getModalParam('headerBar2').module"
                :params="$store.getters.getModalParam('headerBar2').params"
              />
            </HeaderLayout>
          </template>

          <v-sheet
            :class="{
              'pa-4':
                $store.getters.getModalParam('subVuetifyParams2').noPadding !==
                true,
            }"
            v-bind="$store.getters.getModalParam('subVuetifyParams2')"
          >
            <v-icon
              v-if="$store.getters.getModalParam('closeBtn2')"
              @click="$store.dispatch('closeModal2')"
              class="btn-close ml-n1 mt-n2"
              ><CloseIcon size="0.7" class="text-primary"
            /></v-icon>
            <h1 v-if="$store.getters.getModalParam('title2')">
              {{ $t($store.getters.getModalParam("title2")) }}
            </h1>
            <ContentType :content="$store.getters.getModalParam('content2')" />
          </v-sheet>
        </v-dialog>
      </template>
      <template #footer>
        <Menu
          v-if="!('showMenu' in getLayout) || getLayout.showMenu === true"
          :class="{
            'drop-shadow': getLayout.vuetifyParams.dropShadow,
            'footer--fixed': getLayout.vuetifyParams.fixedFooter,
          }"
        />
      </template>
    </content.stretch.layout>
  </base.app.layout>
</template>

<script>
import Menu from "@diabete/components/layout/footer/Menu.vue";
import ContentType from "@shared/components/ContentType.vue";
import HeaderLayout from "@diabete/components/layout/header/HeaderLayout.vue";
import CloseIcon from "@shared/components/icons/CloseIcon.vue";

import { deepMerge } from "@diabete/plugins/utils";

export default {
  name: "AppPageLayout",
  components: {
    Menu,
    ContentType,
    HeaderLayout,
    CloseIcon,
  },
  methods: {
    getModalTransition(modalType) {
      switch (modalType) {
        case "panel-right":
          return "dialog-right-transition";

        case "panel-left":
          return "dialog-left-transition";

        default:
          return "dialog-transition";
      }
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    headerBar: {
      type: Object,
      default: () => ({}),
    },
    layout: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => [],
    },
    routingParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getLayout() {
      let defaultValues = {
        backgroundColor: "bg-surface",
        vuetifyParams: {
          containerFluid: false,
          dropShadow: false,
          class: "",
          fixedFooter: true,
        },
      };
      // Merge the default values with the prop values
      return deepMerge(defaultValues, this.layout || {});
    },
    getHeaderBar() {
      const defaultValues = {
        module: "",
        vuetifyParams: {
          dropShadow: true,
          class: "",
          fixedHeader: true,
        },
      };
      // Merge the default values with the prop values
      return deepMerge(defaultValues, this.headerBar || {});
    },
    containerClasses() {
      const { containerFluid, fixedFooter } = this.getLayout.vuetifyParams;

      const { fixedHeader } = this.getHeaderBar.vuetifyParams;

      return {
        "px-0": containerFluid,
        "px-6": !containerFluid,
        "content--header-padding": fixedHeader,
        "content--footer-padding": fixedFooter,
        "pt-6": !fixedHeader,
        "pb-3": !fixedFooter,
      };
    },
    modalOpen: {
      get: function () {
        return this.$store.getters.getModalParam("open");
      },
      set: function (newValue) {
        if (newValue == false) {
          this.$store.dispatch("closeModal");
        }
      },
    },
    modal2Open: {
      get: function () {
        return this.$store.getters.getModalParam("open2");
      },
      set: function (newValue) {
        if (newValue == false) {
          this.$store.dispatch("closeModal2");
        }
      },
    },
    getClass() {
      return this.layout.class;
    },
    accountNameCapitals() {
      return (
        "" +
        this.$store.getters.userGetProfile.first_name[0] +
        this.$store.getters.userGetProfile.last_name[0]
      );
    },
  },
};
</script>
