<template>
  <div :class="{ 'drop-shadow': dropShadow }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "HeaderLayout",
  props: {
    dropShadow: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style scoped>
.drop-shadow {
  box-shadow: 0 4px 4px 0px rgba(var(--v-theme-drop-shadow), 0.3);
  z-index: 10;
}
</style>