<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.5597 7.0625H7.29393L5.81871 4.41825C5.65312 4.12144 5.33987 3.9375 5 3.9375H3.125C2.60723 3.9375 2.1875 4.35723 2.1875 4.875C2.1875 5.39277 2.60723 5.8125 3.125 5.8125H4.4495L5.90537 8.42206L9.26296 15.8511L9.2664 15.8587L9.56213 16.513L6.19108 20.1088C5.94869 20.3674 5.87282 20.7405 5.99498 21.0732C6.11715 21.4058 6.41647 21.6412 6.76857 21.6814L9.84124 22.0326C13.6846 22.4718 17.5655 22.4718 21.4088 22.0326L24.4815 21.6814C24.9959 21.6227 25.3652 21.158 25.3065 20.6436C25.2477 20.1291 24.783 19.7598 24.2686 19.8186L21.1959 20.1697C17.494 20.5928 13.756 20.5928 10.0541 20.1697L8.83474 20.0304L11.309 17.3912C11.3329 17.3657 11.3551 17.3392 11.3757 17.3118L12.3167 17.4342C13.6353 17.6058 14.9683 17.6395 16.2939 17.5346C19.3857 17.2901 22.2513 15.8189 24.2518 13.4488L24.9744 12.5928C24.9987 12.5641 25.0212 12.5339 25.0418 12.5024L26.3886 10.4501C27.3431 8.99541 26.2996 7.0625 24.5597 7.0625ZM11.4453 15.4301C11.2386 15.4032 11.0603 15.2718 10.9732 15.0826L10.9716 15.0789L8.19592 8.9375H24.5597C24.8082 8.9375 24.9573 9.21363 24.8209 9.42144L23.5052 11.4265L22.8191 12.2394C21.1415 14.2267 18.7387 15.4604 16.1461 15.6655C14.9503 15.76 13.748 15.7297 12.5586 15.5749L11.4453 15.4301Z"
      fill="currentColor"
    />
    <path
      d="M8.75 23.625C7.71447 23.625 6.875 24.4645 6.875 25.5C6.875 26.5355 7.71447 27.375 8.75 27.375C9.78554 27.375 10.625 26.5355 10.625 25.5C10.625 24.4645 9.78554 23.625 8.75 23.625Z"
      fill="currentColor"
    />
    <path
      d="M20.625 25.5C20.625 24.4645 21.4645 23.625 22.5 23.625C23.5355 23.625 24.375 24.4645 24.375 25.5C24.375 26.5355 23.5355 27.375 22.5 27.375C21.4645 27.375 20.625 26.5355 20.625 25.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
