export default {
  global: {
    elevation: 0,
  },
  VBtn: {
    color: "primary",
    rounded: "lg",
    height: 56,
    textTransform: "none",
  },
  VBtnSecondary: {
    color: "surface",
    rounded: "lg",
    height: 56,
  },
  VBtnIcon: {
    color: "surface",
    rounded: "lg",
    height: 10,
    density: "compact",
  },
  VBtnSmall: {
    color: "primary",
    rounded: "lg",
    height: 43,
    textTransform: "uppercase",
    density: "compact",
    class: "font-weight-bold text-overline",
  },
  VBtnNav: {
    height: 40,
    rounded: "xl",
    density: "compact",
    color: "transparent",
    size: "x-small",
  },
  VTooltip: {
    location: "top",
  },
  VCheckbox: {
    color: "primary",
    density: "comfortable",
    hideDetails: "auto",
  },
  VRadioGroup: {
    color: "primary",
    density: "comfortable",
    hideDetails: "auto",
  },
  VSheet: {
    elevation: 0,
    border: "0",
  },
  VTextField: {
    color: "primary",
    variant: "solo",
    rounded: "sm",
    bgColor: "surface",
  },
  VTextArea: {
    color: "primary",
    variant: "solo",
    rounded: "sm",
    bgColor: "surface",
  },
  VOtpInput: {
    variant: "solo",
    bgColor: "background",
    height: 66,
    rounded: "lg",
  },
  VTextarea: {
    variant: "outlined",
    color: "primary",
    hideDetails: "auto",
    density: "comfortable",
  },
  VCard: {
    elevation: 5,
    class: "pa-4",
    VCardTitle: {
      class: "text-body-2 text-uppercase",
      weight: "bold",
    },
    VCardSubtitle: {
      class: "text-body-2 text-uppercase text-lighttext",
    },
    VCardOutlined: {
      background: "surface",
    },
    VCardText: {
      class: "text-body-1",
    },
    VChip: {
      rounded: "lg",
      class: "font-weight-bold text-caption",
      size: "small",
    },
    VCardActions: {
      class: "pa-0",
      style: "min-height: auto",
      VBtn: {
        color: "secondary",
        dencity: "compact",
        variant: "text",
        class: "text-h4 font-weight-regular pa-0 bg-tansparent mb-0",
        height: 24,
        ripple: false,
      },
    },
  },
  VCardHelp: {
    class: "px-4 py-5",
    rounded: "lg",
    VCardItem: {
      class: "pa-0",
    },
    VCardTitle: {
      class: "text-h3 text-surface",
      opacity: 1,
      textTransform: "normal",
    },
    VCardSubtitle: {
      class: "text-subtitle-2 font-weight-regular",
      opacity: 1,
      textTransform: "normal",
    },
  },
  VTabs: {
    color: "darktext",
    bgColor: "surface",
    VBtn: {
      rounded: "0",
    },
  },
  VDivider: {
    color: "primary",
    thickness: 1,
    class: "border-opacity-10 mb-0 mt-0 pt-0",
  },
  VAlert: {
    variant: "outlined",
    background: "surface",
    density: "compact",
  },
  VAlertError: {
    color: "error",
    variant: "outlined",
    background: "surface",
    class: ["bg-surface"],
    type: "error",
  },
  VAlertInfo: {
    color: "info",
    variant: "outlined",
    background: "surface",
    class: ["bg-surface"],
    type: "info",
  },
  VAlertSuccess: {
    color: "success",
    variant: "outlined",
    background: "surface",
    class: ["bg-surface"],
    type: "success",
  },
  VList: {
    density: "compact",
    ripple: false,
    activeClass: "bg-transparent",
    VListItem: {
      color: "primary",
      ripple: false,
    },
    VBtn: {
      variant: "text",
      color: "primary",
      density: "compact",
      size: "small",
      ripple: false,
      height: 36,
      class: "mb-0 px-0 font-weight-regular text-body-2",
    },
    VListHeader: {
      class: "font-weight-bold",
    },
  },
  VSwitch: {
    color: "success",
    ripple: false,
    inline: true,
    class: "w-100",
    density: "compact",
    VLabel: {
      class: "pl-0",
      opacity: 1,
    },
  },
};
