export function deepMerge(target, source) {
  if (typeof target !== "object" || typeof source !== "object") {
    return source;
  }

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (source[key] instanceof Object) {
        // If the property is an object, recursively merge
        target[key] = deepMerge(target[key] || {}, source[key]);
      } else {
        // Otherwise, assign the property directly
        target[key] = source[key];
      }
    }
  }

  return target;
}
