import { defineAsyncComponent } from "vue";

export default {
  import: (app) => {
    app.component(
      "diabete.home.title",
      defineAsyncComponent(() => import("@diabete/components/home/Title.vue"))
    );
    app.component(
      "diabete.home.my_orders_block",
      defineAsyncComponent(() =>
        import("@diabete/components/home/MyOrdersBlock.vue")
      )
    );
    app.component(
      "diabete.home.my_devices_block",
      defineAsyncComponent(() =>
        import("@diabete/components/home/MyDevicesBlock.vue")
      )
    );
    app.component(
      "diabete.home.help_block",
      defineAsyncComponent(() =>
        import("@diabete/components/home/HelpBlock.vue")
      )
    );

    app.component(
      "diabete.header",
      defineAsyncComponent(() =>
        import("@diabete/components/layout/header/Header.vue")
      )
    );

    app.component(
      "auth.layout",
      defineAsyncComponent(() => import("@diabete/components/auth/Layout.vue"))
    );

    app.component(
      "base.app.layout",
      defineAsyncComponent(() =>
        import("@diabete/components/layout/BaseAppLayout.vue")
      )
    );

    app.component(
      "content.stretch.layout",
      defineAsyncComponent(() =>
        import("@diabete/components/layout/ContentStretchLayout.vue")
      )
    );

    return app;
  },
};
