<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Ellipse 65"
      cx="15.375"
      cy="15.5"
      r="11.25"
      stroke="currentColor"
      stroke-width="2"
    />
    <circle
      id="Ellipse 93"
      cx="15.375"
      cy="23"
      r="0.625"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      id="Vector 123"
      d="M15.375 20.5V18.7264C15.375 17.5461 16.1303 16.4982 17.25 16.125V16.125C18.3697 15.7518 19.125 14.7039 19.125 13.5236V12.8821C19.125 10.8762 17.4988 9.25 15.4929 9.25H15.375C13.3039 9.25 11.625 10.9289 11.625 13V13"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
